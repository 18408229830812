

















import { Vue, Component, Prop } from "vue-property-decorator";
import { Upload } from "element-ui";
@Component({
    components: {
        "el-upload": Upload
    }
})
export default class FormItemUpimg extends Vue {
    @Prop({
        type: [String, Number, Boolean],
        required: false,
        default: false
    })
    value: any; //表单数据

    @Prop({ type: Object, required: true, default: () => {} })
    formItemOpt: any; //表单参数

    imageUrl: string = "";
    loading: boolean = false;

    async upload(data: any) {
        this.loading = true;
        const getopt = this.$api.apiList.uploadImg;
        const opt = getopt();
        const img = data.file;

        let formdata = new FormData();
        formdata.append("file", img);
        let subconunt = 0; //提交次数
        let sub: any = async () => {
            subconunt++;
            let result = await this.$axios({
                method: opt.method,
                url: opt.url,
                headers: opt.headers,
                data: formdata
            })
                .then((res: any) => {
                    if (res.data) {
                        if (res.data.path) {
                            return {
                                status: 0,
                                msg: "上传成功",
                                data: res.data
                            };
                        } else {
                            return {
                                status: 1,
                                msg: "上传失败：服务器未返回图片路径",
                                data: res.data
                            };
                        }
                    } else {
                        return {
                            status: 1,
                            msg: "上传失败：通信成功，但服务器没有返回值"
                        };
                    }
                })
                .catch((err: any) => {
                    if (err.response) {
                        let error: any = err.response.data.errors; //返回值
                        let errKey: any = Object.keys(error); //存放"错误信息key"的数组
                        let errmsg: any = error[errKey[0]][0]; //第一个错误msg
                        return {
                            status: 2,
                            msg: `上传失败:[${err.response.status}]${errmsg}`,
                            data: err.response
                        };
                    } else {
                        if (subconunt < 5) {
                            return false;
                        } else {
                            return {
                                status: 3,
                                msg: `上传失败:[${err.message}]服务器未返回错误信息`,
                                data: err.config
                            };
                        }
                    }
                });

            if (result == false) {
                result = await sub();
                return result;
            } else {
                return result;
            }
        };
        let res = await sub().then((response: any) => {
            return response;
        });
        return res;
    }

    handleAvatarSuccess(res: any, file: any) {
        if (res.status == 0) {
            this.imageUrl = res.data.path;
            this.change(res.data.path);
        }
        this.$notify({
            title: res.status == 0 ? "成功" : "错误",
            message: res.msg,
            type: res.status == 0 ? "success" : "error"
        });
        this.loading = false;
    }

    beforeAvatarUpload(file: any) {
        // console.log(file.type);
        const isJPG = file.type === "image/jpeg";
        const isPNG = file.type == "image/png";
        const isLt2M = file.size / 1024 / 1024 < 2;

        if (!isJPG && !isPNG) {
            this.$nextTick(() => {
                this.$message.error("上传照片只能是 JPG 或 PNG 格式!");
            });
        }
        if (!isLt2M) {
            this.$nextTick(() => {
                this.$message.error("上传照片大小不能超过 2MB!");
            });
        }

        return (isJPG || isPNG) && isLt2M;
    }

    // 钩子，选中值发生变化时触发
    change(data: any) {
        this.$emit("onchange", data);
    }

    mounted() {
        if (this.value) {
            this.imageUrl = this.value;
        } else {
        }
    }
}
